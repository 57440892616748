export default class AlertService {
  constructor({ containerSelector = "#flash .col" } = {}) {
    this.containerSelector = containerSelector;
  }

  show({ message, alertType = "info" }) {
    const $alert = $(`<div class="alert alert-${alertType} fade show">`);
    const $closeButton = $('<a class="close" data-dismiss="alert">&times;</a>');
    $alert.html(message)
      .prepend($closeButton);

    $(this.containerSelector).prepend($alert);
  }
}
