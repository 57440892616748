export default class Modal {
  constructor({
    title,
    url                = "",
    content            = "",
    id                 = "modal",
    modalDidLoad       = function() {},
    additionalHandlers = [],
  }) {

    this.title        = title;
    this.url          = url;
    this.content      = content;
    this.id           = id;
    this.modalDidLoad = modalDidLoad;
    this.$modal       = this._cloneModal();

    this.$modal.on("show.bs.modal", this.modalShouldShow.bind(this));
    this.$modal.on("hidden.bs.modal", this.modalDidHide.bind(this));
    additionalHandlers.forEach((handler) => this.$modal.on(...handler));
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Public API
  ////////////////////////////////////////////////////////////////////////////////
  show() {
    this.$modal.modal("show");
  }

  hide() {
    this.$modal.modal("hide");
  }

  find(selector) {
    return this.$modal.find(selector);
  }

  setTitle(title) {
    this.find(".modal-header h5")
      .html(title);
  }

  setContent(content) {
    this.find(".modal-body").html(content);
  }

  loadRemoteContent(url) {
    this.find(".modal-body")
      .load(url, this.modalDidLoad.bind(this));
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Events
  ////////////////////////////////////////////////////////////////////////////////
  modalShouldShow(event) {
    this.setTitle(this.title);
    if (this.url) { this.loadRemoteContent(this.url); }
    else if (this.content) { this.setContent(this.content); }
  }

  modalDidHide(event) {
    this.$modal.remove();
  }


  ////////////////////////////////////////////////////////////////////////////////
  // Private
  ////////////////////////////////////////////////////////////////////////////////
  _cloneModal() {
    let $modal = $("#modal-tmpl").clone();
    $modal.attr("id", this.id);
    $('body').append($modal);

    return $modal;
  }
}
