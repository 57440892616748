export default class DriverTripController {
  static selector = "#trips";

  static shouldRender() {
    return $(DriverTripController.selector).length;
  }

  constructor(parent) {
    this.parent = parent;
    this.watchForUnauthorizedTripRequests();
    this.watchForAuthorizedTripRequests();
  }

  watchForUnauthorizedTripRequests() {
    const content = `
      <a href="/drivers/sign_in?{{ params }}" class="btn btn-outline-primary btn-lg">Sign In</a>
      <p>Don't have an account? Want to drive for Group One?</p>
      <a href="/drivers/sign_up" class="btn btn-outline-primary btn-lg">Create an Account</a>
    `;

    $('#trips').on('click', "[data-toggle=authentication-modal]", (event) => {
      const $target = $(event.currentTarget);
      const params  = $.param({ driver_return_to: $target.data('driver-return-to') });

      this.parent.showModal({
        title:   "Already have an account? Log in!",
        id:      "require-registration-modal",
        content: content.replace("{{ params }}", params)
      });
    });
  }

  watchForAuthorizedTripRequests() {
    $('#trips').on('click', "[data-toggle=request-trip-modal]", (event) => {
      const $target = $(event.currentTarget);
      const url     = $target.data('url');

      this.parent.showModal({
        title: "Request Trip",
        url:   url,
        id:    "request-trip-modal",
      })
    })
  }
}
