import "../styles/site.sass";

import DriverTripController    from './controllers/driver_trip_controller';
import LiveDashboardController from './controllers/live_dashboard_controller';
import AlertService            from './services/alert_service';
import Modal                   from './modal';

const images = require.context('../images', true);

// Even though jQuery is available in this context, without this it won't be
// available to us in js.erb files nor in the console.
window.$ = window.jQuery = jQuery;

//
// The App object is a high-level object that has a lifecycle that is active
// the entire duration of the time that the site is being used. It's
// responsible for encapsulating and orchestrating the various controllers that
// take responsibility for different areas of the site, and for being a central
// point of reference for individual services.
//
class App {
  constructor() {
    this.alertService = new AlertService();
    this.controller   = this.startController();

    this.highlightMissingTranslations();
    this.enhanceMultipleSelectBoxes();
  }

  startController() {
    if (DriverTripController.shouldRender()) {
      return new DriverTripController(this);
    }
    else if (LiveDashboardController.shouldRender()) {
      return new LiveDashboardController(this);
    }
  }

  highlightMissingTranslations() {
    $('body.development span.translation_missing').popover({
      content: function() { this.getAttribute('title') },
      trigger: 'hover',
      delay:   { "show": 0, "hide": 5000 }
    });
  }

  enhanceMultipleSelectBoxes() {
    $('select[multiple]').selectpicker();
  }

  redirectToLocation(url) {
    window.location = url;
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Modals
  ////////////////////////////////////////////////////////////////////////////////
  showModal({ title, id, content = "", url = "", additionalHandlers = []}) {
    this.modal = new Modal({
      title:              title,
      // additionalHandlers: [ ["hidden.bs.modal", () => { delete this.modal; }] ],
      additionalHandlers: additionalHandlers,
      id:                 id,
      content:            content,
      url:                url,
    });

    this.modal.show();
  }

  setModalContent(content) {
    this.modal.setContent(content);
  }

  hideModal() {
    this.modal.hide();
    delete this.modal;
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Alerts
  ////////////////////////////////////////////////////////////////////////////////
  showSuccessAlert(message) {
    this.alertService.show({ message: message, alertType: "success" });
  }

  showInfoAlert(message) {
    this.alertService.show({ message: message, alertType: "info" });
  }

  showErrorAlert(message) {
    this.alertService.show({ message: message, alertType: "danger" });
  }
}

$(function() {
  window.app = new App();
});
