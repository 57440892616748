/* eslint no-console:0 */

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context("../images", true)
// const imagePath = (name) => images(name, true)

import Rails from "@rails/ujs";
Rails.start();

// You can import any libraries here that you want but they won't be usable
// inside the sass source. Instead, for those libraries, @import them inside
// src/site.sass.

import "font-awesome/css/font-awesome.min.css";
import "popper.js/dist/popper.min.js";
import "bootstrap/dist/js/bootstrap";
import "bootstrap-select/dist/js/bootstrap-select";
import "bootstrap-select/dist/css/bootstrap-select";

import '../src/app'
