export default class LiveDashboardController {
  static selector = "#live";

  static shouldRender() {
    return $(LiveDashboardController.selector).length;
  }

  constructor(parent) {
    this.parent = parent;
    this.watchForEditRequests();
  }

  watchForEditRequests() {
    $('#live').on('click', "[data-toggle=edit-dispatch-notes-modal]", (event) => {
      const $target = $(event.currentTarget);
      const url     = $target.data('url');
      const id      = $target.data('id');

      this.parent.showModal({
        title: `Editing Notes for Trip ${id}`,
        url:   url,
        id:    "edit-dispatch-notes-modal",
      });
    });
  }
}
